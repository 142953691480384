export default [
	{ label: 'Braus', emission: 2016, unit: 'cap/any' },
	{ label: 'Vaques càrniques', emission: 2212, unit: 'cap/any' },
	{ label: 'Vedells', emission: 1148, unit: 'cap/any' },
	{ label: 'Corders', emission: 229.32, unit: 'cap/any' },
	{ label: 'Marrans', emission: 229.32, unit: 'cap/any' },
	{ label: 'Ovelles', emission: 229.32, unit: 'cap/any' },
	{ label: 'Bocs', emission: 143.64, unit: 'cap/any' },
	{ label: 'Cabres', emission: 143.64, unit: 'cap/any' },
	{ label: 'Cabrits', emission: 143.64, unit: 'cap/any' },
	{ label: 'Eugues', emission: 547.68, unit: 'cap/any' },
	{ label: 'Pollins', emission: 547.68, unit: 'cap/any' },
	{ label: 'Semental', emission: 547.68, unit: 'cap/any' },
];
