<template lang="html">
	<article class="step">
		<h2>{{ $t(`${sector}.a1.title`) }}</h2>
		<p>
			{{ $t(`${sector}.a1.desc`) }}
			<strong>{{ $t('global.tip.mandatory') }}</strong>
		</p>
		<step-fuels :sector="sector" abast="a1" :valid.sync="valid.fixes" required />
		<step-mobils :sector="sector" abast="a1" :valid.sync="valid.mobils" required />
		<step-gases :sector="sector" abast="a1" :valid.sync="valid.gases" required />
		<step-livestock
			v-if="getDetails.sector.id === 'A'"
			:sector="sector"
			abast="a1"
			:valid.sync="valid.livestock"
			required />
	</article>
</template>

<script>
import StepMixin from '@/mixins/StepMixin';
import StepFuels from '../blocks/StepFuels.vue';
import StepMobils from '../blocks/StepMobils.vue';
import StepGases from '../blocks/StepGases.vue';
import StepLivestock from '../blocks/StepLivestock.vue';

export default {
	name: 'StepA1',
	mixins: [StepMixin],
	components: { StepFuels, StepMobils, StepGases, StepLivestock },
};
</script>
